<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="C端域名配置" name="1">
                    <el-form class="form-box" ref="CdomainForm" v-bind:rules="rules" v-bind:model="CdomainForm" label-position="left" label-suffix=":" size="large" label-width="120px">
                        <el-form-item label="是否开启私域" prop="aftersales">
                            <el-radio-group v-model="CdomainForm.is_top">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitCdomainForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="B端域名配置" name="2">
                    <el-form class="form-box" ref="BdomainForm" v-bind:rules="rules" v-bind:model="BdomainForm" label-position="left" label-suffix=":" size="large" label-width="120px">
                        <el-form-item label="是否开启私域" prop="aftersales">
                            <el-radio-group v-model="BdomainForm.is_top">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitBdomainForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { request } from '@/common/request';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb
    },
    data() {
        return {
            activeName: '1',
            menuList: ['设置', '域名配置'],
            loading: false,
            rules: {
                cancel: [{
                    required: true,
                    message: '时间不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字"
                }],
            },
            CdomainForm: {},
            BdomainForm: {},
        }
    },
    created() {
        this.loadCdomainForm()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            switch (tab.paneName) {
                case '1':
                    this.loadCdomainForm()
                    break
                case '2':
                    this.loadBdomainForm()
                    break
            }
        },

        // 获取c端配置
        loadCdomainForm() {
            this.loading = true;
            request.get('/system/shopping/mall/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.CdomainForm = ret.data;
                    this.$refs.CdomainForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取B端配置
        loadBdomainForm() {
            this.loading = true;
            request.get('/system/shopping/mall/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.BdomainForm = ret.data;
                    this.$refs.BdomainForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 保存C端配置
        submitCdomainForm() {
            let _this = this
            request.post('/system/shopping/mall/edit', this.CdomainForm).then(ret => {
                if (ret.code == 1) {
                    _this.loadCdomainForm()
                    this.$refs.CdomainForm.clearValidate()
                    this.$message.success('商城配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存B端配置
        submitBdomainForm() {
            let _this = this
            request.post('/system/shopping/mall/edit', this.BdomainForm).then(ret => {
                if (ret.code == 1) {
                    _this.loadBdomainForm()
                    this.$refs.BdomainForm.clearValidate()
                    this.$message.success('商城配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
    },
}

</script>

<style scoped>
.desc {
    margin-left: 10px;
    font-size: 14px;
    color: #606266;
}

.inline {
    display: inline-block;
    width: 25%;
}

.money >>> .el-input {
    width: 100px !important;
}

.icon_box {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #dddddd;
    margin-left: 10px;
    text-align: center;
    line-height: 30px;
}

.el-form-item--large.el-form-item {
    margin-top: 30px;
}
</style>